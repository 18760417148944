<script>
import User from "@/components/User.vue";

export default {
    name: "UvedListitem",
    components: {
        User
    },

    props: {
        item: {
            type: Object,
            required: true
        },

        columns: {
            type: Array,
            required: true
        },

        userBlock: {
            type: Boolean,
            required: true
        }
    },

    computed: {
        activeColumns() {
            return this.columns.filter(column => column.active);
        }
    },

    methods: {
        deleteItem(item) {
            this.$emit('deleteItem', item);
        },

        formatPhoneNumber(phone) {
            if (!phone) return '';
            return phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');
        }
    }
}
</script>

<template>
    <div class="item">
        <div class="item__header">
            <div class="item__id text-color-light small-text">#{{ item.id }}</div>
            <div class="item__created_at text-color-light small-text">{{ item.created_at }}</div>
        </div>

        <div class="item__description">
            <div class="item__name">
                <router-link v-if="item.permissions.includes('uved.read_detail')" :disabled="userBlock" text plain :to="'/uved/' + item.uuid + '/show'">
                    {{ item.name }}
                </router-link>
                <span v-else class="font_weight_500">{{ item.name }}</span>
                <v-icon v-if="item.permissions.includes('uved.delete')"  @click="deleteItem(item)" :title="$t('delete')">
                    mdi-delete-outline mdi-18px
                </v-icon>
            </div>

            <div v-if="item.note" class="item__note text-color-light">{{ item.note }}</div>

        </div>

        <div class="item__data" v-if="activeColumns.length > 0">
            <div class="text-info" v-for="column in activeColumns" :key="column.name">
                <div class="text-info__label">
                    <div>{{ column.label }}</div>
                </div>
                <div class="text-info__value">
                    <slot :name="column.prop" :item="item">
                        <div>{{ item[column.prop] }}</div>
                    </slot>
                </div>
            </div>
        </div>

        <div class="item__footer">
            <User v-if="item.author" :item="item.author" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
</style>
